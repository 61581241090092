import AppHeader from '../AppHeader/AppHeader';
import './stayInTouch.scss';

export function StayInTouch(){

  return (
    <div>
      <div style={{ marginLeft: '-10px'}}>
        <AppHeader title={'Keep in Touch'}/>
      </div>
       
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{maxWidth: '330px'}}>
          <div className={'stay-in-touch-container'}>
            <div className={'stay-in-touch-container-header'}>
              <div style={{width: '300px', marginTop: '50px'}}><strong>Thank you for playing RewardRush!</strong></div>
            </div>
            <div className={'stay-in-touch-img-container'}>
            <div style={{marginBottom: '20px'}}><img src={'./rr_general.png'} alt={'General RewardRush'} width={330} style={{borderRadius: '8px'}} /></div>
            </div>
            {/* <div className={'stay-in-touch-header'}>
              This page is only available during a tour
            </div> */}
            <div className={'stay-in-touch-txt'}>
              RewardRush Season 1 has now come to a close. It was a pleasure traveling with you across Europe and South America!
            </div>
            
            <div className={'stay-in-touch-txt'}>
              Please stay in touch with us and your fellow travelers in the RewardRush chat room on our Discord.
            </div>
            <a style={{textDecoration: 'none'}} href={'https://discord.gg/bCfmU5pNZW'}>
              <div className='stay-in-touch-container-discord'>
                <div>
                  <img style={{marginRight: '12px', marginTop: '10px'}} width={25} src={'./discord-mark-white.svg'} alt={'discord logo'} />
                </div>
                <div>
                  Discord
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StayInTouch