import './inventory.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import Loading from "../../components/Loading/Loading";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { inventoryService } from "../../services/inventory";
import { useNavigate, useSearchParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { checkOffSeason } from '../../services/season';
import { telemetryService } from '../../services/telemetry';


type InventoryVouchers = {
  bonus_vouchers: number;
  standard_vouchers: number;
  standard_vouchers_limit: number;
}

type InventorySouvenirLandmark = {
  name: string;
  souvenirs: number;
  image_url: string;
  _id: string;
}

type InventorySouvenir = {
  _id: string;
  name: string;
  landmarks: InventorySouvenirLandmark[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type InventoryArtifact = {
  count: number;
  description: string;
  image_url: string;
  name: string;
  _id: string;
}

type InventoryLocation = {
  _id: string;
  name: string;
  image_url: string;
  in_progress: boolean;
}
function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className='workshop-tab-content' sx={{p: 3}}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function Inventory() {

  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [vouchers, setVouchers] = useState<InventoryVouchers>({
    bonus_vouchers: 0,
    standard_vouchers: 0,
    standard_vouchers_limit: 0
  });
  const [specialTickets, setSpecialTickets] = useState({
    name: 'Special',
    gold_tickets: 0
  });
  const [souvenirs, setSouvenirs] = useState<InventorySouvenir[]>([]);
  const [artifacts, setArtifacts] = useState<InventoryArtifact[]>([]);
  const navigate = useNavigate();
  const [accessibleLocations, setAccessibleLocations] = useState<InventoryLocation[]>([]);
  const [otherLocations, setOtherLocations] =  useState<InventoryLocation[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [solitaireUri, setSolitaireUri] = useState('');
  const [isVisible, setIsVisible] = useState(false);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setSearchParams(params => {
      params.set("tab", JSON.parse(newValue.toString()));
      return params;
    });
  };

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const processClick = () => {
    window.open(solitaireUri);
    telemetryService.recordTelemetry('solitaire_install').then((r) => {});
  }

  const getInventory = useCallback(() => {
    setIsLoading(true);
    sleep(1000).then(() => {
      inventoryService.getInventory().then((r: any) => {
        if (r && !r.error) {
          if (r.vouchers) {
            setVouchers(r.vouchers);
          }
          if (r.tickets) {
            if(r.tickets.accessible_locations) {
              setAccessibleLocations(r.tickets.accessible_locations);
            }
            if(r.tickets.other_locations) {
              setOtherLocations(r.tickets.other_locations);
            }
            if (r.tickets.special) {
              setSpecialTickets(r.tickets.special);
            }
            if(r.artifacts) {
              setArtifacts(r.artifacts);
            }
          }
          if (r.souvenirs) {
            setSouvenirs(r.souvenirs);
          }
          if (r.solitaireUri) {
            setSolitaireUri(r.solitaireUri);
          }
          if (r.user_id) {
            if (r.user_id.toString() === "66c53796f5fb3df35446c2bd") {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          }
          if(searchParams && searchParams.get("tab")) {
            const t = searchParams.get("tab");
            if(t) {
              setTabValue(parseInt(t));
            }
          }
          setIsLoading(false);
        } else {
          if (r.message && checkOffSeason(r.message.toString())){
            navigate(`/stay_in_touch`);
          }
          setIsLoading(false);
        }
      })
    })
  }, [searchParams, navigate])

  useEffect(() => {
    getInventory();
  }, [getInventory]);

  const redeemVouchers = (type: string) => {
    navigate(`/booking?type=${type}`);
  }

  const navigateToLandmark = (id: string) => {
    navigate(`/landmark?id=${id}`);
  }

  const navigateToMap = () => {
    navigate(`/map`);
  }

  return (
    <div style={{minHeight: '100vh', background: '#FBF8EE', paddingTop: '50px'}}>
      {isLoading && <Loading color={'#E81961'}/>}
      {!isLoading &&
        <div>
          <AppHeader title={'Inventory'}/>
          <div>
            <div>
              <Box sx={{width: '100%'}}>
                <Box>
                  <Tabs
                    sx={{
                      ".MuiButtonBase-root" : {
                        background: `#FBF8EE !important`,
                        color: '#B2B2BA !important'
                      },
                      ".Mui-selected": {
                        background: `#FBF8EE !important`,
                        color: '#5F607B !important',
                      }
                    }}

                    TabIndicatorProps={{style: {background: "#5F607B"}}}
                    value={tabValue}
                    onChange={handleChange}
                    variant={'fullWidth'}
                  >
                    <Tab className="workshop-tabs-lbl" label="Vouchers"/>
                    <Tab className="workshop-tabs-lbl" label="Tickets"/>
                    <Tab className="workshop-tabs-lbl" label="Souvenirs"/>
                    <Tab className="workshop-tabs-lbl" label="Artifacts"  />
                  </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                  <div>

                    <div className={'inventory-vouchers-container'}>
                      <div className={'inventory-vouchers-header'}>Standard Voucher</div>
                      <div className={'inventory-vouchers-description'}>You get these vouchers throughout the day. The max amount that you have at one time is 3!</div>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className={'inventory-vouchers-txt'}>Owned: {vouchers.standard_vouchers}</div>
                        <div>
                          <Button
                            disabled={vouchers.standard_vouchers === 0}
                            onClick={() => redeemVouchers('standard')}
                            className={vouchers.standard_vouchers === 0 ? 'inventory-vouchers-footer-btn-dsbl' : 'inventory-vouchers-footer-btn'}
                          >
                            Use
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className={'inventory-vouchers-container'}>
                      <div className={'inventory-vouchers-header'}>Bonus Voucher</div>
                      <div className={'inventory-vouchers-description'}>These are rewards awarded for achievements on the Rewarded Play app. You can stock up as many as you want!</div>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className={'inventory-vouchers-txt'}>Owned: {vouchers.bonus_vouchers}</div>
                        <div>
                          <Button
                            disabled={vouchers.bonus_vouchers === 0}
                            onClick={() => redeemVouchers('bonus')}
                            className={vouchers.bonus_vouchers === 0 ? 'inventory-vouchers-footer-btn-dsbl' : 'inventory-vouchers-footer-btn'}
                          >
                            Use
                          </Button>
                        </div>
                      </div>
                    </div>

                    {
                      isVisible && 
                      <div className={'inventory-vouchers-container-sol'} >
                        <div className={'inventory-vouchers-sol-header'}>Want more Bonus Vouchers?</div>
                        <div className={'inventory-vouchers-sol-txt'}>
                          Earn additional Bonus Vouchers by installing and playing SolitaireRush!
                          Earn more Bonus Vouchers faster by joining Player Edge in the SolitaireRush app.
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <Button className={'inventory-vouchers-footer-btn'} onClick={() => processClick()}>Install</Button>
                        </div>
                      </div>
                    }

                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>


                  <div>
                    <div className='inventory-tickets-container' style={{display: 'flex'}}>
                      <div style={{marginRight: '12px', paddingTop: '4px'}}>
                        <img width={27} height={22} alt={'gold ticket'} src={'./gold_ticket_small.svg'}/>
                      </div>
                      <div>
                        <div className={'inventory-tickets-container-title'}>Gold Tickets</div>
                        <div className={'inventory-tickets-container-txt'} style={{marginBottom: '16px'}}>Go to ANY location at ANY time!</div>
                        <div className={'inventory-tickets-container-txt'} style={{display: 'flex', justifyContent: 'space-between'}}>
                          <div style={{paddingTop: "4px"}}>Owned: {specialTickets.gold_tickets}</div>
                          <div><Button onClick={() => navigateToMap()} className={'inventory-go-to-map-btn'}>Go to map</Button></div>
                        </div>
                      </div>
                    </div>

                    <Accordion defaultExpanded elevation={0} sx={{
                      '&:before': {
                        display: 'none',
                      },
                      "& .MuiButtonBase-root": {
                        background: '#FBF8EE',
                        padding: 0,
                        color: '#E81961',
                        fontFamily: 'TypoHoopRegular'
                      },
                      "& .MuiAccordion-region": {
                        background: '#FBF8EE',
                      },
                      "& .MuiAccordionDetails-root": {
                        padding: 0,
                      }
                    }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        Accessible locations
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={'inventory-tickets-subheader'}>
                          These locations are playable by you right now because you own a green or blue Ticket for them. Tap to visit!
                        </div>
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          {accessibleLocations.map((l, index) =>
                            <div key={index} className={'inventory-souvenirs-item-container'}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{maxHeight: '70px', maxWidth: '73px', position: 'relative'}}>
                                  <div>
                                    <img onClick={() => navigateToLandmark(l._id)} alt={l.name} style={{height: '70px', width: '73px', paddingLeft: '8px'}} src={l.image_url} />
                                  </div>
                                  {l.in_progress &&
                                    <div style={{position: 'absolute', left: '-12px', top: '2px'}}>
                                      <img src={'./pause_play.svg'} width={18} alt={''} />
                                    </div>
                                  }
                                </div>
                                <div className={'inventory-souvenirs-txt'}>{l.name}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion elevation={0} sx={{
                      '&:before': {
                        display: 'none',
                      },
                      "& .MuiButtonBase-root": {
                        background: '#FBF8EE',
                        padding: 0,
                        color: '#E81961',
                        fontFamily: 'TypoHoopRegular'
                      },
                      "& .MuiAccordion-region": {
                        background: '#FBF8EE',
                      },
                      "& .MuiAccordionDetails-root": {
                        padding: 0,
                      }
                    }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        Other locations
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={'inventory-tickets-subheader'}>
                          These are locations you own only green tickets for but are currently closed. Still accessible via Golden ticket.
                        </div>
                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          {otherLocations.map((l, index) =>
                            <div key={index} className={'inventory-souvenirs-item-container'}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{maxHeight: '70px', maxWidth: '73px', position: 'relative'}}>
                                  <img onClick={() => navigateToLandmark(l._id)} alt={l.name} style={{height: '70px', width: '73px', paddingLeft: '8px'}} src={l.image_url} />
                                </div>
                                <div className={'inventory-souvenirs-txt'}>{l.name}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>


                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                  <div>
                    {souvenirs.map((ta, index) =>
                      <div key={index} className='inventory-souvenirs-container'>
                        <div className='inventory-souvenirs-title'>{ta.name}</div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          {ta.landmarks.map((l, index) =>
                            <div key={index} className={'inventory-souvenirs-item-container'}>
                              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                  <div style={{maxHeight: '70px', maxWidth: '73px', position: 'relative'}}>
                                    <img onClick={() => navigateToLandmark(l._id)} alt={l.name} style={{height: '70px', width: '73px', paddingLeft: '8px'}} src={l.image_url} />
                                    <div className={l.souvenirs === 0 ? 'inventory-souvenirs-empty-count' : 'inventory-souvenirs-have-count'}>
                                      {(l.souvenirs) ? l.souvenirs : ''}
                                    </div>
                                  </div>
                                  <div className={'inventory-souvenirs-txt'}>{l.name}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={3}>
                  {artifacts.length === 0 ?
                    <div className={'inventory-artifacts-item-container-empty'}>You do not have any artifacts</div>
                    :
                    <div className={'inventory-artifacts-item-container'}>
                      {artifacts.map((ar,index) =>
                        <div key={index} className={'inventory-artifacts-inner-container'}>
                          <div>
                            <img alt={ar.name} style={{height: '70px', width: '73px', paddingLeft: '6px'}} src={ar.image_url} />
                            <div className={ar.count === 0 ? 'inventory-souvenirs-empty-count' : 'inventory-souvenirs-have-count'}>
                              {(ar.count) ? ar.count : ''}
                            </div>
                          </div>
                          <div className={'inventory-souvenirs-txt'}>{ar.name}</div>
                        </div>
                      )}
                    </div>
                  }
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
