import './booking.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import {
  Button,
  Dialog,
  DialogContent, DialogProps,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup, Snackbar
} from "@mui/material";
import { bookingService } from "../../services/booking";
import Loading from "../../components/Loading/Loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { checkOffSeason } from '../../services/season';

type BookingAgent = {
  _id: string;
  name: string;
  enabled: boolean;
  image_url: string;
  metadata: {
    green: number;
    no_ticket: number;
    blue: number;
    gold: number;
  }
}

export function Booking() {

  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState<BookingAgent[]>([]);
  const [vouchers, setVouchers] = useState({
    standard_vouchers: 0,
    bonus_vouchers: 0
  });
  const [voucherValue, setVoucherValue] = React.useState('standard');
  const [searchParams] = useSearchParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({
    ticket_rarity: 'green',
    title: 'Congratulations!',
    landmark: '',
    gained_ticket: false,
    image_url: '',
    message: '',
    landmark_id: null,
    is_open: false
  });
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherValue((event.target as HTMLInputElement).value);
  };

  const getAgents = useCallback(() => {
    setIsLoading(true);
      bookingService.getAgents().then((r: any) => {
        if(r && !r.error) {
          if(r.agents) {
            setAgents(r.agents);
          }
          if(r.vouchers) {
            setVouchers(r.vouchers);
          }
          const type = searchParams.get('type');
          if(type && (type === 'standard' || type === 'bonus')) {
            setVoucherValue(type);
          }
          setIsLoading(false);
        } else if (r && r.error) {
          if (r.message && checkOffSeason(r.message.toString())){
            navigate(`/stay_in_touch`);
          }
          setErrorMessage(r.message);
          setShowError(true);
          setIsLoading(false);
        }
      })
  }, [searchParams, navigate])

  useEffect(() => {
    getAgents();
  }, [getAgents]);

  const selectAgent = (agent: BookingAgent) => {
    setIsLoading(true);
    bookingService.selectAgent({agent_id: agent._id, voucher_type: voucherValue}).then((r) => {
      setIsLoading(false);
      if(r) {
        const tmp = r;
        if(r && r.error) {
          tmp.title = r.data.message;
          tmp.landmark_id = null;
        } else {
          if(r.gained_ticket) {
            tmp.title = 'Congratulations!';
          }
        }
        setDialogInfo(tmp);
      }
      setOpenDialog(true);
    })
  }

  const onDialogClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenDialog(false);
    getAgents();
  }

  const startPlaying = () => {
    if(dialogInfo.landmark_id !== null) {
      navigate(`/landmark?id=${dialogInfo.landmark_id}`);
    } else {
      navigate('/map');
    }
  }

  return (
    <div style={{minHeight: '100vh', background: '#FBF8EE', paddingTop: '50px'}}>
      <AppHeader title={'Booking'}/>
      {isLoading && <Loading color={'#E81961'} />}
      {!isLoading &&
        <div style={{background: "transparent", minHeight: '100vh', padding: '16px'}}>
          <div className={'booking-radio-container'}>
            <div className={'booking-form-header'}>
              Type of voucher you are redeeming?
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <FormControl>
                <RadioGroup
                  row
                  value={voucherValue}
                  onChange={handleChange}
                >
                  <FormControlLabel value="standard" control={<Radio sx={{
                    '&.Mui-checked': {
                      color:'#107B5E'
                    },
                  }} />} label={<span className={'booking-form-selection'}>{'Standard (' + vouchers.standard_vouchers + ')'}</span>}/>
                  <FormControlLabel value="bonus" control={<Radio sx={{
                    '&.Mui-checked': {
                      color:'#107B5E'
                    },
                  }} />} label={<span className={'booking-form-selection'}>{'Bonus (' + vouchers.bonus_vouchers + ')'}</span>} />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={'booking-form-header'} style={{marginBottom: '16px'}}>
              Who are you booking with?
            </div>
          </div>
          <div className={'booking-agents-container'}>
            {agents.map((ta, index) =>
                <div onClick={() => ta.enabled ? selectAgent(ta) : () => false} style={{position: 'relative'}} key={index}  className={ta.enabled ? 'booking-agent-container' : 'booking-agent-container-disabled'}>
                  <div style={!ta.enabled ? {opacity: '0.2'} : {}}>
                    <img alt={'agent pic'} className={'booking-agents-img'} src={ta.image_url} />
                  </div>
                  <div className='booking-agent-title'>{ta.name}</div>
                  <div className={'booking-agent-row'}>
                    <img style={{marginRight: '12px', marginLeft: '8px'}} alt={'green ticket'} width={12} src={'./green_ticket_small.svg'} />
                    <div className='booking-agent-txt'>Green (Common): {(ta.metadata.green * 100).toFixed()}%</div>
                  </div>
                  <div className={'booking-agent-row'}>
                    <img style={{marginRight: '12px', marginLeft: '8px'}} alt={'blue ticket'} width={12} src={'./blue_ticket_small.svg'} />
                    <div className='booking-agent-txt'>Blue (Rare): {(ta.metadata.blue * 100).toFixed()}%</div>
                  </div>
                  <div className={'booking-agent-row'}>
                    <img style={{marginRight: '12px', marginLeft: '8px'}} alt={'gold ticket'} width={12} src={'./gold_ticket_small.svg'} />
                    <div className='booking-agent-txt'>Gold (Wild): {(ta.metadata.gold * 100).toFixed()}%</div>
                  </div>
                  <div className={'booking-agent-row'}>
                    <img style={{marginRight: '12px', marginLeft: '8px'}} alt={'no ticket'} width={12} src={'./black_ticket_small.svg'} />
                    <div className='booking-agent-txt'>No Ticket: {(ta.metadata.no_ticket * 100).toFixed()}%</div>
                  </div>
                  {!ta.enabled &&
                    <div style={{position: 'absolute', top: '43px', left: '43px'}}>
                      <img alt={'lock'} src={'./agent_lock.svg'} />
                    </div>
                  }
                </div>
            )}
          </div>
        </div>
      }

      <Dialog
        open={openDialog}
        // open={true}
        onClose={onDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "14px",
              background: "#FFF"
            },
          },
        }}
      >
        <DialogContent>
         <div>
           <div  onClick={() => onDialogClose({}, 'escapeKeyDown')} style={{color: "#CCCCCC", position: 'absolute', right: '4px', top: '4px', display: 'inline-block'}}>
             <HighlightOffIcon width={'28px'} height={'28px'} />
           </div>
           <div style={{textAlign: 'center', marginBottom: '24px', marginTop: '16px'}}>
             {dialogInfo.ticket_rarity === 'green' && <img alt={'ticket'} width={250} src={'./green_ticket_small.svg'} />}
             {dialogInfo.ticket_rarity === 'blue' && <img alt={'ticket'} width={250} src={'./blue_ticket_small.svg'} />}
             {dialogInfo.ticket_rarity === 'gold' && <img alt={'ticket'} width={250} src={'./gold_ticket_small.svg'} />}
           </div>
           <div className='notifications-dialog-header'>{dialogInfo.title}</div>
           <div className='notifications-dialog-txt'>{dialogInfo.message}</div>
           {(!dialogInfo.is_open && dialogInfo.gained_ticket) && <div className='notifications-dialog-txt'>
             This location is currently closed, but don’t worry, your ticket has been sent to your inventory to be used later!
           </div>}
           <div style={{display: 'flex', justifyContent: 'center'}}>
             {!dialogInfo.is_open && <Button onClick={() => onDialogClose({}, 'escapeKeyDown')} className='notifications-dialog-btn'>Close</Button>}
             {dialogInfo.is_open && <Button onClick={() => startPlaying()} className='notifications-dialog-btn'>Start Playing</Button>}
           </div>
         </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={showError}
        autoHideDuration={3000}
        onClose={() => setShowError(false)}
        message={errorMessage}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />

    </div>
  )
}