import './map.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cityService } from "../../services/city";
import Loading from "../../components/Loading/Loading";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { Snackbar } from "@mui/material";
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import { checkOffSeason } from '../../services/season';

type City = {
  _id: string;
  name: string;
  country_id: string;
}

type Cities = City[]

type Landmark = {
  _id: string;
  name: string;
  owned: number;
  spares: number;
  image_url: string;
}

type SelectedCity = City & {
  description: string;
  landmarks: Landmark[];
}


export function Map() {

  const [isSelected, setIsSelected] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState<Cities>([]);
  const [selectedCity, setSelectedCity] = useState<SelectedCity>({
    _id: '',
    name: '',
    country_id: '',
    description: '',
    landmarks: []
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const onButtonClick = (city: string) => {
    const foundCity = cities.find((c) => {
      return c.name.toLowerCase() === city.toLowerCase();
    });
    if(foundCity) {
      cityService.getCity(foundCity._id).then((res: SelectedCity) => {
        if(res) {
          setSelectedCity(res);
          setIsSelected(true);
          setSearchParams({city: res._id});
        }
      })
    }
  }

  const navigateToLandMark = (id: string) => {
    navigate(`/landmark?id=${id}`);
  }

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getCities = useCallback(() => {
    setIsLoading(true);
    sleep(1000).then(() => {
      cityService.getCities().then((r: {cities: Cities, error?: boolean, message: string}) => {
        if(r.cities) {
          setCities(r.cities);
          const city = searchParams.get('city');
          if(city !== null) {
            const foundCity = r.cities.find((c) => {
              return c._id === city;
            });
            if(foundCity) {
              cityService.getCity(foundCity._id).then((res: SelectedCity) => {
                if(res) {
                  setSelectedCity(res);
                  setIsSelected(true);
                  setIsLoading(false);
                } else {
                  setIsLoading(false);
                }
              })
            }
          } else {
            setIsLoading(false);
          }
        }

        if(r.error) {
          if (r.message && checkOffSeason(r.message.toString())){
            navigate(`/stay_in_touch`);
          }
          setPopupMessage(r.message);
          setShowPopup(true);
          setIsLoading(false);
        }
      })
    })
  }, [searchParams, navigate])

  useEffect(() => {
    getCities();
  }, [getCities]);

  const resetMap = () => {
    setIsSelected(false);
    setSelectedCity({
      _id: '',
      name: '',
      country_id: '',
      description: '',
      landmarks: []
    });
    searchParams.delete('city');
    setSearchParams(searchParams);
  }

  const navigateToTradeHub = () => {
    navigate(`/trade_hub`);
  }

  const navigateToBooking = () => {
    navigate(`/booking`);
  }

  return (
    <div className={'map-bg'}>
      <AppHeader title={'Map'}/>
      {isLoading && <Loading  color={''}/>}
      {!isLoading &&
        <div className={'map-inner-bg'} style={{background: "", height: '100vh', padding: '16px'}}>
          <div>
            <div className={'map-breadcrumb'} style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex'}}>
                <div style={{paddingTop: '7px', paddingLeft: '6px', display: 'flex', marginRight: '16px'}}>
                  <div>
                    <img style={{marginRight: '8px'}} alt={'map pin'} src={'./map_pin.svg'} width={17} height={21} />
                  </div>
                  <div onClick={() => resetMap()} className={'map-breadcrumb-txt'}>Brazil</div>
                </div>
                {isSelected && <div style={{paddingTop: '7px', display: 'flex'}}>
                  <div>
                    <img style={{marginRight: '8px'}} src={'./city_icon.svg'} alt={'city icon'} width={17} height={21} />
                  </div>
                  <div className={'map-breadcrumb-txt-city'}>{selectedCity.name}</div>
                </div>}
              </div>
              <div>
                {/*Right*/}
                <div style={{paddingTop: '3px'}}>
                  <img style={{marginRight: '8px'}} alt={'breadcrumb icon'} src={'./breadcrumb_icon.svg'} width={29} height={29} />
                </div>
              </div>
            </div>
            <div className={'map-icons-container'}>
              <div className={'map-icon'} style={{marginRight: '12px'}} onClick={() => navigateToTradeHub()}>
                <LoopOutlinedIcon style={{color: '#F9FED5'}} height={'30px'} width={'30px'}  />
              </div>
              <div className={'map-icon'} onClick={() => navigateToBooking()}>
                <LocalActivityIcon style={{color: '#F9FED5'}} height={'24px'} width={'24px'}  />
              </div>
            </div>


            {!isSelected ?
              <div style={{display:'flex', justifyContent: 'center', width: '100%', marginBottom: '12px'}}>

                <div style={{position: 'relative'}}>
                  <img src={'./brazil_map.svg'} alt={'Brazil'}/>
                  <div onClick={() => onButtonClick('amazonas')} style={{top: "55px", left: '35px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Amazonas</div>
                  </div>
                  <div onClick={() => onButtonClick('bahia')} style={{top: "155px", right: '85px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Bahia</div>
                  </div>
                  <div onClick={() => onButtonClick('brasília')} style={{top: "235px", right: '115px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Brasília</div>
                  </div>
                  <div onClick={() => onButtonClick('mato grosso')} style={{top: "125px", left: '110px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Mato Grosso </div>
                  </div>
                  <div onClick={() => onButtonClick('minas gerais')} style={{top: "215px", right: '15px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Minas Gerais</div>
                  </div>
                  <div onClick={() => onButtonClick('paraná')} style={{top: "380px", right: '120px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Paraná</div>
                  </div>
                  <div onClick={() => onButtonClick('pernambuco')} style={{top: "105px", right: '0px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Pernambuco</div>
                  </div>
                  <div onClick={() => onButtonClick('rio grande do sul')} style={{top: "440px", right: '95px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Rio Grande do Sul
                    </div>
                  </div>
                  <div onClick={() => onButtonClick('rio de janeiro')} style={{top: "270px", right: '10px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div>
                      <div className={'map-city-label'}>Rio de Janeiro</div>
                    </div>
                  </div>
                  
                  <div onClick={() => onButtonClick('são paulo')} style={{top: "325px", right: '75px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>São Paulo</div>
                  </div>
                </div>
              </div>
              :
              <div>

                {selectedCity.name.toLowerCase() === 'amazonas' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./amazonas_bg.svg'} alt={'Amazonas Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === "adolpho lisboa's market" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '180px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={90} height={25} src={l.image_url} alt={"Adolpho Lisboa's Market"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "cipiá's indigenous community" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '85px', right: '120px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={"Cipiá's Indigenous Community"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'amazonas river' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '265px', left: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Amazonas River'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }


                {selectedCity.name.toLowerCase() === 'bahia' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./bahia_bg.svg'} alt={'Bahia Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'chapada diamantina' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '115px', left: '20px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65}src={l.image_url} alt={'Chapada Diamantina'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'pelourinho' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '120px', right: '20px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} height={70} src={l.image_url} alt={'Pelourinho'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'pedra furada island' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '265px', right: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={80} src={l.image_url} alt={'Pedra Furada Island'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'brasília' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./brasília_bg.svg'} alt={'Brasília Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'palácio do planalto' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '105px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} height={50} src={l.image_url} alt={'Palácio do Planalto'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'palácio itamaraty' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '185px', right: '125px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} height={50} src={l.image_url} alt={'Palácio Itamaraty'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'catedral met. n. s. aparecida' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '60px', left: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Catedral Met. N. S. Aparecida'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'mato grosso' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./mato_grosso_bg.svg'} alt={'Mato Grosso Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === "araras square" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '320px', right: '105px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} height={60} src={l.image_url} alt={"Araras Square"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "véu de noiva's waterfall" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '80px', left: '95px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={"Véu de Noiva's Waterfall"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "pn do pantanal matogrossense" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '210px', left: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={"PN do Pantanal Matogrossense"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'minas gerais' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={400} src={'./minas_gerais_bg.svg'} alt={'Minas Gerais Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'natonal park of serra da canastra' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '135px', left: '30px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={40} src={l.image_url} alt={'Natonal Park of Serra da Canastra'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'mirante dos canyons' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '280px', left: '55px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Mirante dos Canyons'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "st. francisco de assis church" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '300px', right: '25px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={45} src={l.image_url} alt={"St. Francisco de Assis Church"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'paraná' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./paraná_bg.svg'} alt={'Paraná Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === "cataratas do iguaçu" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '220px', left: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={"Cataratas do Iguaçu"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'catedral bas. menor n. s. da glória' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '45px', left: '90px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={45} src={l.image_url} alt={'Catedral Bas. Menor N. S. da Glória'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "curitiba's botanical garden" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute',top: '220px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={"Curitiba's Botanical Garden"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'pernambuco' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./pernambuco_bg.svg'} alt={'Pernambuco Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === "house of olinda's giant dolls" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute',top: '90px', right: '0px' }}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={"House of Olinda's Giant Dolls"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "marco zero's square" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '255px', right: '35px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={"Marco Zero's Square"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "brennand's ceramic workshop" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '193px', left: '-12px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={"Brennand's Ceramic Workshop"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'rio grande do sul' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./rio_grande_do_sul_bg.svg'} alt={'Rio Grande do Sul Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'castelinho caracol' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '10px', left: '65px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={'Castelinho Caracol'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'fonte do amor eterno' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '280px', left: '15px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Fonte do Amor Eterno'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'n. s. de lourdes parish' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '165px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={40} src={l.image_url} alt={'N. S. de Lourdes Parish'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'rio de janeiro' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./rio_de_janeiro_bg.svg'} alt={'Rio de Janeiro Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'pão de açúcar' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '90px', right: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={85} src={l.image_url} alt={'Pão De Açúcar'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'copacabana' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '230px', right: '110px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={'Copacabana'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "cristo redentor" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '95px', left: '20px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={"Cristo Redentor"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'são paulo' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./são_paulo_bg.svg'} alt={'São Paulo Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'museu do ipiranga' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '190px', right: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={90} src={l.image_url} alt={'Museu do Ipiranga'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'mercado municipal' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '35px', right: '60px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={90} src={l.image_url} alt={'Mercado Municipal'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "ponte estaiada" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '265px', left: '20px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={"Ponte Estaiada"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }


              </div>
            }


          </div>
        </div>
      }
      <Snackbar
        open={showPopup}
        autoHideDuration={3000}
        onClose={() => setShowPopup(false)}
        message={popupMessage}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />
    </div>
  )
}